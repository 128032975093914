import React from "react"
import "./coreValues.scss"
import { Grid, useMediaQuery, useTheme } from "@material-ui/core"
import { StaticImage } from "gatsby-plugin-image"
import { t } from "i18next"

const AboutUsCoreValues = () => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down("md"))

  return (
    <>
      <div className="core-values">
        <div className="core-content">
          <div className="tag-label">CORE VALUE ASETKU</div>
          <StaticImage
            src="../../assets/images/capable-pict.svg"
            alt=""
            className="symbol"
          />

          <Grid
            container
            className={isMobile ? "video-desc mobile" : "video-desc"}
          >
            <Grid item lg={5} md={12} sm={12} xs={12}>
              <iframe
                src="https://www.youtube.com/embed/FZSKMvxjW64?si=mbEoAFjUPNaMkHU7"
                title="Asetku Core Value"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
                className="video-core-value"
              />
            </Grid>
            <Grid item className="capable-part" lg={6} md={12} sm={12} xs={12}>
              <div className="capable-title">
                The <span className="orange">Capable</span> P2P Lending Partner
                in Indonesia
              </div>
              <div className="capable-detail">{t("about-us.capable-desc")}</div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="capable-container" />
      <div className="capable-content">
        <div className="circle-container">
          <div className="circle">C</div>
          <StaticImage
            src="../../assets/images/icons//stepper-dot.svg"
            className="line"
          />
          <p className="description">
            <span>C</span>lient <br />
            Focused
          </p>
        </div>
        <div className="circle-container">
          <div className="circle">A</div>
          <StaticImage
            src="../../assets/images/icons//stepper-dot.svg"
            className="line"
          />

          <p className="description">
            <span>A</span>gile
          </p>
        </div>
        <div className="circle-container">
          <div className="circle">P</div>
          <StaticImage
            src="../../assets/images/icons//stepper-dot.svg"
            className="line"
          />

          <p className="description">
            <span>P</span>rovide <br />
            Results Driven <br />
            Performance
          </p>
        </div>
        <div className="circle-container">
          <div className="circle">AB</div>
          <StaticImage
            src="../../assets/images/icons//stepper-dot.svg"
            className="line"
          />

          <p className="description">
            <span>A</span>ctive <br />
            Colla<span>B</span>oration
          </p>
        </div>
        <div className="circle-container">
          <div className="circle">L</div>
          <StaticImage
            src="../../assets/images/icons//stepper-dot.svg"
            className="line"
          />

          <p className="description">
            <span>L</span>earning & <br />
            Enabling <br />
            Others
          </p>
        </div>
        <div className="circle-container">
          <div className="circle">E</div>
          <StaticImage
            src="../../assets/images/icons//stepper-dot.svg"
            className="line"
          />

          <p className="description">
            <span>E</span>ffective <br />
            Communication
          </p>
        </div>
      </div>
    </>
  )
}

export default AboutUsCoreValues
