import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { t } from "i18next"
import "./visionMission.scss"
import { useMediaQuery, useTheme } from "@material-ui/core"

const AboutUsVision = () => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down("md"))

  const missionList = [
    {
      labelClass: "label-tag mission-1",
      title: t("about-us.mission-1-tagline"),
      desc: t("about-us.mission-1-tagline-detail"),
    },
    {
      labelClass: "label-tag mission-2",
      title: t("about-us.mission-2-tagline"),
      desc: t("about-us.mission-2-tagline-detail"),
    },
    {
      labelClass: "label-tag mission-3",
      title: t("about-us.mission-3-tagline"),
      desc: t("about-us.mission-3-tagline-detail"),
    },
  ]
  return (
    <div className="section-vision">
      <div className="title-desc">
        <div className="title">{t("about-us.vision-mission")}</div>
        <div className="desc">{t("about-us.vision-mission-detail")}</div>
      </div>

      <div className="vision-mission-box vision">
        {!isMobile && (
          <StaticImage
            src="../../assets/images/icons/arrow-dart.png"
            alt=""
            className="icon"
          />
        )}

        <div className="title-desc">
          <div className="label-tag">{t("about-us.vision")}</div>
          <div className="title">{t("about-us.vision-tagline")}</div>
          <div className="desc">{t("about-us.vision-tagline-detail")}</div>
        </div>
      </div>

      {!isMobile ? (
        <div className="mission-part">
          {missionList.map((e, i) => (
            <div className="vision-mission-box" key={i}>
              <div className={e.labelClass}>{`${t("about-us.mission")} #${
                i + 1
              }`}</div>
              <div className="title-desc">
                <div className="title">{e.title}</div>
                <div className="desc">{e.desc}</div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <>
          {missionList.map((e, i) => (
            <div className="vision-mission-box" key={i}>
              <div className={e.labelClass}>{`${t("about-us.mission")} #${
                i + 1
              }`}</div>
              <div className="title-desc">
                <div className="title">{e.title}</div>
                <div className="desc">{e.desc}</div>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  )
}

export default AboutUsVision
