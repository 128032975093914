import React, { useState } from "react"
import "./career.scss"
import { StaticImage } from "gatsby-plugin-image"
import { Grid } from "@material-ui/core"
import { t } from "i18next"

const AboutUsCareer = () => {
  const [joinClickChannel, setJoinClickChannel] = useState()

  return (
    <div className="asetku-career">
      <div className="grow-with-us">
        <div className="title">{t("about-us.grow-your-career-title")}</div>
      </div>
      <div className="career-channel">
        <div className="desc">{t("about-us.grow-your-career-desc")}</div>
        <div className={`join-part ${joinClickChannel ?? ""}`}>
          <div className="title">Are you the next Astro?</div>
          <div className="buttons">
            <a
              href="https://id.linkedin.com/company/asetku"
              target="_blank"
              rel="noopener noreferrer"
              className="button-item linkedin"
              onClick={() => {
                setJoinClickChannel("linkedin")
              }}
            >
              <StaticImage src="../../assets/images/linkedin.png" />
            </a>
            <a
              href="https://www.kalibrr.com/id-ID/c/asetku/jobs"
              target="_blank"
              rel="noopener noreferrer"
              className="button-item kalibrr"
              onClick={() => {
                setJoinClickChannel("kalibrr")
              }}
            >
              <StaticImage src="../../assets/images/kalibrr.png" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUsCareer
