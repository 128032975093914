import React, { useRef } from "react"
import { Grid, useMediaQuery, useTheme } from "@material-ui/core"
import { StaticImage } from "gatsby-plugin-image"

import i18next, { t } from "i18next"
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/bundle"

import { EffectCoverflow, Navigation, Pagination, Autoplay } from "swiper"
import "./banner.scss"

const AboutUsBanner = () => {
  const sliderRef = useRef(null)
  const isMobile = useMediaQuery(useTheme().breakpoints.down("md"))

  const langIsID = i18next.language === "id"
  return (
    <Grid container className="about-us-banner">
      <Grid item md={6} className="desc-section">
        {!isMobile ? (
          <>
            <StaticImage
              src="../../assets/images/icons/asetku-mascot.png"
              alt=""
              className="mascot"
            />
            <div className="title-desc">
              <div className="title">{t("about-us.tagline")}</div>
              <div className="desc">{t("about-us.about-us-detail")}</div>
            </div>
          </>
        ) : (
          <>
            <div className="title-desc">
              <div className="title">
                <StaticImage
                  src="../../assets/images/icons/asetku-mascot.png"
                  alt=""
                  className="mascot"
                />
                {t("about-us.tagline")}
              </div>
              <div className="desc">{t("about-us.about-us-detail")}</div>
            </div>
          </>
        )}
      </Grid>
      <Grid item md={6} className="about-us-banner-img-div">
        <Swiper
          onBeforeInit={swiper => {
            sliderRef.current = swiper
          }}
          slidesPerView={1}
          coverflowEffect={{
            rotate: 0,
            stretch: -42,
            scale: 1,
            depth: 0,
            modifier: 1.0,
            slideShadows: false,
          }}
          modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
          pagination={{
            clickable: true,
          }}
          autoplay={true}
          loop={true}
          scrollbar={{ draggable: true }}
        >
          <SwiperSlide>
            {langIsID ? (
              <StaticImage
                src={"../../assets/images/picture/about/banner/banner-1-id.jpg"}
                alt=""
                className="about-us-banner-img"
              />
            ) : (
              <StaticImage
                src={"../../assets/images/picture/about/banner/banner-1-en.jpg"}
                alt=""
                className="about-us-banner-img"
              />
            )}
          </SwiperSlide>
          <SwiperSlide>
            {langIsID ? (
              <StaticImage
                src={"../../assets/images/picture/about/banner/banner-2-id.jpg"}
                alt=""
                className="about-us-banner-img"
              />
            ) : (
              <StaticImage
                src={"../../assets/images/picture/about/banner/banner-2-en.jpg"}
                alt=""
                className="about-us-banner-img"
              />
            )}
          </SwiperSlide>
        </Swiper>
        <div
          className="swipper-pagination-button prev"
          onClick={() => {
            sliderRef.current?.slidePrev()
          }}
        >
          <StaticImage
            src="../../assets/images/icons/arrow-gallery-left.png"
            className="nav-icon"
            alt=""
          />
        </div>

        <div
          className="swipper-pagination-button next"
          onClick={() => {
            sliderRef.current?.slideNext()
          }}
        >
          <StaticImage
            src="../../assets/images/icons/arrow-gallery-right.png"
            className="nav-icon"
            alt=""
          />
        </div>
      </Grid>
    </Grid>
  )
}

export default AboutUsBanner
